import { useState, useEffect } from "react";
import { GAME_VALUES } from "../../constants/GameConstants";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import LineGame from "../LineGame";
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Timer from "../Timer";

import { GAME_MINUTES } from "../../constants/GameConstants";

const useStyles = makeStyles(theme => ({
  game: {
    marginTop: "7em",
    position: "relative"
  },
  fab: {
    margin: theme.spacing(2),
  },
  points: {
    margin: "auto"
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function Board({ currentPlayer, currentComputer, onFinishTurn, onWrongAnswer, setCards, onEndGame, isBattle, actionType }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [pointChoosen, setPointChoosen] = useState(null);
  const [canPlay, setCanPlay] = useState(true);

  useEffect(() => {
    if(pointChoosen) {
      const currentCard = { ...currentPlayer.currentCard };

      if(pointChoosen === currentCard.note.value) {
        currentCard.good_following_responses++;

        const newCards = currentPlayer.notes.map(card => card.id === currentCard.id ? currentCard : card);
        setCards(newCards, currentCard.id);

        setOpen(false);
        setPointChoosen(null);
        setCanPlay(false)
      } else {
        currentCard.good_following_responses = 0;
        
        const newCards = currentPlayer.notes.map(card => card.id === currentCard.id ? currentCard : card);
        setCards(newCards, currentCard.id);

        setOpen(true);
        onWrongAnswer();
      }
    }
  }, [pointChoosen]);

  useEffect(() => {
    if(!canPlay) {
      onFinishTurn();
    }
  }, [canPlay])

  const updateCanPlay = bool => {
    setCanPlay(bool);
  }

  return (
    <>
      <Grid item xs={6}>
        <Grid item container justify="center" xs={12} >
          <Typography variant="h4">
            Vous
          </Typography>
        </Grid>
        <LineGame player={currentPlayer} atLeft={true} canPlay={canPlay} isBattle={isBattle} actionType={actionType} setCanPlay={updateCanPlay}/>
      </Grid>
      <Grid item xs={6}>
        <Grid item container justify="center" xs={12} >
          <Typography variant="h4">
            Ordinateur
          </Typography>
        </Grid>
        <LineGame player={currentComputer} canPlay={canPlay} isBattle={isBattle} actionType={actionType} setCanPlay={updateCanPlay}/>
      </Grid>
      <Grid container item xs={12} justify="center">  
        <Timer
          initialMinute={GAME_MINUTES}
          onEndGame={onEndGame}
        />
      </Grid>
      <Grid item container xs={12} justify="center">
        { open ? <Alert severity="error">ce n'est pas la bonne durée, essaie un autre choix !</Alert> : null }
      </Grid>
      <Grid item container xs={12} justify="center">
        { currentPlayer.currentCard ? (
          <>
            {GAME_VALUES.map(point => (
              <Fab key={point} disabled={!canPlay} color={ pointChoosen === point ? "secondary" : "primary" } className={classes.fab} onClick={() => {
                setPointChoosen(point);
              }}>
                {point}
              </Fab>
            ))}
          </>
        ) : null}
      </Grid>
    </>
  )
}