import * as Yup from "yup";

export const INITIAL_VALUES_INSCRIPTION = {
  name: "",
  email: ""
};

export const INSCRIPTION_FORM_SCHEMA = Yup.object().shape({
  name: Yup.string().required("Un nom est requis"),
  email: Yup.string().email("Email non valide").required("Un email est requis")
}); 