export default class Player {
  static createPlayerFromJSON(data) {
    return new Player(
      data.id,
      data.name,
      data.email,
      data.first_game,
      data.first_start,
      data.score,
      data.status,
      data.notes,
    );
  }

  // when id = 0, that mean this is a bot
  constructor(id = 0, name = "Computer", email = "", first_game = null, first_start = null, score = 0, status = null, notes = []) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.first_game = first_game;
    this.first_start = first_start;
    this.score = score;
    this.status = status;
    this.notes = notes;

    this.currentScore = 0;
  }
  
  setCurrentCard(card) {
    this.currentCard = card;
    //this.currentCard.uuid = uuid();
  }

  addToScore(number) {
    this.currentScore += number;
  }

  resetCurrentScore() {
    this.currentScore = 0;
  }

  resetCurrentCard() {
    this.currentCard = null;
  }
}

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}