import React from 'react'
import { useState, useEffect } from 'react';

export default function Timer({ initialMinute = null, initialSeconds = 0, onEndGame }) {
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] =  useState(initialSeconds);

    useEffect(()=>{
      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
              onEndGame();
              clearInterval(myInterval);
          } else {
              setMinutes(minutes - 1);
              setSeconds(59);
          }
        } 
      }, 1000)
        return ()=> { clearInterval(myInterval) };
    });

    return (
        <div>
        { minutes === 0 && seconds === 0
            ? <h1> Partie terminée ! </h1> 
            : ( minutes || seconds
              ? <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1> 
              : null
            )
        }
        </div>
    )
}