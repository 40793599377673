import getInstance from "./getInstance";

export default {
  async getPlayerByEmail(email) {
    try {
      const res = await getInstance().get(`/players/email/${email}`);
      return res;
    } catch (err) {
      console.log(err.message);
      return err;
    }
  },

  async createPlayer(player) {
    try {
      const res = await getInstance().post("/players", player);
      return res;
    } catch (err) {
      console.log(err.message);
      return err;
    }
  }
};