import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ParameterCard from "../../components/ParameterCard";
import CircularProgress from '@material-ui/core/CircularProgress';

import * as actions from "../../actions";

import LineService from "../../services/LineService";
import NoteService from "../../services/NoteService";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: 450,
  },
  titleGrid: {
    marginTop: "5em",
    marginBottom: "5em"
  }
}));

function Parameters (props) {
  const { currentPlayer } = props;
  const { setCards } = props.actions;

  const [state, setState] = useState({
    lines: [],
    list: []
  });
  
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      try{
        const newState = { ...state };
        let cards = [];

        let res = await LineService.getLines();
        newState.lines = res.data;

        res = await NoteService.getCardsByPlayer(currentPlayer);
        cards = res.data;

        for(const line of newState.lines) {
          let notes = line.notes;
          notes = notes.sort((a, b) => (a.rank > b.rank) ? 1 : -1)

          newState.list.push(
            (
              <Grid item container justify="center">
                {line.notes.map(
                  note => (
                    <ParameterCard note={note} key={note.id} cardsOfCurrentPlayer={cards} setCards={setCards} />
                  )
                )}
              </Grid>
            )
          );
        }

        setState(newState);
      } catch(e) {
        console.error(e);
      }
    }

    if(currentPlayer && currentPlayer.notes) {
      fetchData();
    }
    // return () => {}; => componentWillUnmount
  }, []); //componentDidMount

  return(
    <div>
      <Grid container item xs={12} justify="center" className={classes.titleGrid}>
        <Typography variant="h2">
          Paramètres
        </Typography>
      </Grid>
      <Grid container justify="center">
        {state.list.length > 0 ? state.list : <CircularProgress color="secondary" />}
      </Grid>
    </div>
  )
};

const mapStateToProps = state => ({
  currentPlayer: state.game.currentPlayer
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
	mapDispatchToProps,
)(Parameters);
