import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import cards from './cards'
import game from './game'

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  cards,
	game,
});

export default rootReducer