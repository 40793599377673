import Player from "../models/Player";

export const GAME_MINUTES = 3;
export const GAME_VALUES = [4, 3, 2, 1.5, 1, 0.75, 0.50, 0.25];
export const MIN_CARDS_IN_GAME = 3;

export const DEFAULT_STATE_GAME = {
  currentPlayer: null,
  currentComputer: null,
  cardsPlayed: [],
  gameProcessing: false,
  timeInMinutes: null,
  result: null,
  nb_correct_answers: 0,
  nb_wrong_answers: 0,
  cardsUpdated: [],
  cardsUnlocked: [],
  cardsValid: []
};