import { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { HAVE_CHOOSE_GAME } from '../../constants/ActionsTypes';
import { VERSO_CARD_URI } from "../../constants/CardsConstants";

export default function RectoCard({ visibleFaceImage, style, player, isBattle, actionType, setCanPlay }) {
  const [display, setDisplay] = useState("none");
  const [isVerso, setIsVerso] = useState(false);

  useEffect(() =>  {
    const time = player.id === 0 ? 2000 : 1000;

    setTimeout(() => {
      setDisplay("flex");
    }, time);
    
  }, []);

  useEffect(() => {
    console.log(actionType)
    if(actionType === HAVE_CHOOSE_GAME) {
      setDisplay("none");
      const time = player.id === 0 ? 2000 : 1000;
      
      if(isBattle) {
        setTimeout(() => {
          setIsVerso(true);
          setDisplay("flex");

          setTimeout(() => {
            setDisplay("none");

            setTimeout(() => {
              setIsVerso(false);
              setDisplay("flex");

              if(player.id === 0) {
                setCanPlay(true);
              }
            }, time);
          }, 500)
        }, 500);
      } else {
        setTimeout(() => {
          setDisplay("flex");

          if(player.id === 0) {
            setCanPlay(true);
          }
        }, time);
      }
    }
  }, [actionType]);

  const useStyles = makeStyles({
    container: {
      position: "relative",
      width: "150px",
      height: "250px",
      display,
      borderRadius: "0.25rem",
      userSelect: "none",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${isVerso ? VERSO_CARD_URI : visibleFaceImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'white',
      margin: "1em",
      ...style
    },
  });
  
  const classes = useStyles();

  return (
    <div 
      className={classes.container}>
    </div>
  )
}

/*let oldUuid = null;

export default function RectoCard({ visibleFaceImage, style, player, canPlay, actionType }) {
  const containerRef = useRef(null);
  const [display, setDisplay] = useState("none");

  const loadImage = () => {
    if(visibleFaceImage) {
      const containerElement = containerRef.current;
      containerElement.innerHTML = "";

      const image = new Image(150, 250);

      image.src = visibleFaceImage.routeImage;

      containerElement.appendChild(image);

      image.onload = () => {
        setTimeout(() => {
          setDisplay("flex");
        }, player.id === 0 ? 2000 : 1000)
      };
    }
  };

  

  if(oldUuid !== visibleFaceImage.uuid && canPlay) {

  }

  useEffect(() =>  {
    //loadImage();
    console.log(visibleFaceImage);
  }, []);

  const useStyles = makeStyles({
    container: {
      position: "relative",
      width: "150px",
      height: "250px",
      display,
      borderRadius: "0.25rem",
      userSelect: "none",
      margin: "1em",
      ...style
    },
    img: {
      width: "150px",
      height: "250px",
      borderRadius: "0.25rem"
    }
  });
  
  const classes = useStyles();

  return (
    <div 
      className={classes.container}
      ref={containerRef}
    >
      <img className={classes.img} src={visibleFaceImage}/>
    </div>
  )
}*/