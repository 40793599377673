import { useState, useRef } from "react";
  import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import MenuGame from "../MenuGame";
import ResultModal from "../ResultModal";
import Board from "../Board";

const useStyles = makeStyles(theme => ({
  game: {
    marginTop: "7em",
    position: "relative"
  },
  fab: {
    margin: theme.spacing(2),
  },
  points: {
    margin: "auto"
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function Game(props) {
  const { timeInMinutes, gameProcessing, currentPlayer, currentComputer, result, cardsUnlocked, cardsValid, isBattle, actionType } = props.game;
  const { startGame, endGame, haveChooseGame, resetResult, addWrongAnswer, setCards } = props.actions;

  const gameRef = useRef(null);
  const classes = useStyles();
  let open = result != null && result != undefined;

  const onFinishTurn = () => {
    haveChooseGame();
  };

  const onStartGame = () => {
    startGame();
  };

  const onEndGame = () => {
    endGame();
  };

  const onWrongAnswer = () => {
    addWrongAnswer();
  }

  const onClose = () => {
    resetResult();
  };

  return (
    <Grid container className={classes.game} ref={gameRef}>
      { (gameProcessing && currentPlayer && timeInMinutes) ? 
        <Board 
          onFinishTurn={onFinishTurn} 
          currentPlayer={currentPlayer} 
          currentComputer={currentComputer} 
          onWrongAnswer={onWrongAnswer}
          setCards={setCards}
          onEndGame={onEndGame}
          isBattle={isBattle}
          actionType={actionType}
        /> : null }
      <MenuGame onEndGame={onEndGame} onStartGame={onStartGame} gameProcessing={gameProcessing} />
      <ResultModal open={open} result={result} onClose={onClose} cardsUnlocked={cardsUnlocked} cardsValid={cardsValid} />
    </Grid>
  )
};
