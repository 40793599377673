import getInstance from "./getInstance";

export default {
  async getLines() {
    try {
      const res = await getInstance().get("/lines");
      return res;
    } catch (err) {
      return err;
    }
  },
};