import getInstance from "./getInstance";

export default {
  async getNoteById(id) {
    try {
      const res = await getInstance().get(`/notes/${id}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getCardsByPlayer(player) {
    try {
      const res = await getInstance().get(`/play-withs?player=${player.id}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async updateCardPlayer(card) {
    let cardUpdated = { ...card };
    delete cardUpdated.note;
    delete cardUpdated.updated_at;

    try {
      const res = await getInstance().put(`/play-withs/${card.id}`, card);
      return res;
    } catch (err) {
      return err;
    }
  },

  async updateCardsPlayer(cards, player) {
    try {
      const res = await getInstance().put(`/play-withs/update/many/${player.id}`, cards);
      return res;
    } catch (err) {
      return err;
    }
  }
};