import { useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import HomeIcon from '@material-ui/icons/Home';
import { ConnectedRouter } from 'connected-react-router';

import {
  Home,
  Parameters,
  Stats,
  LegalInformations
} from "./views";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link
} from "react-router-dom";

import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuIcon: {
    marginRight: "0.5em",
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#58B93F"
  },
  footerBar: {
    backgroundColor: "#58B93F",
    marginTop: "5em"
  }
}));

function App({ history }) {
  const appRef = useRef(null);
  const classes = useStyles();

  const openInNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
  }

  return (
    <Router>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            La bataille rythmique
          </Typography>
          <Button color="inherit" onClick={() => history.push("/")}>
            <HomeIcon className={classes.menuIcon} />
            Accueil
          </Button>
          <Button color="inherit" onClick={() => openInNewTab("https://www.lirelamusique.fr/contact-1/")}>
            <MailIcon className={classes.menuIcon} />
            Nous contacter
          </Button>
          <Button color="inherit" onClick={() => openInNewTab("https://www.lirelamusique.fr/commande-bataille-rythmique-1/")}>
            <LocalGroceryStoreIcon className={classes.menuIcon} />
            Commander
          </Button>
        </Toolbar>
      </AppBar>

      <ConnectedRouter history={history} >
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/parametres">
            <Parameters />
          </Route>
          <Route path="/informations-legales">
            <LegalInformations />
          </Route>
          <Route path="/statistiques">
            <Stats />
          </Route>
        </Switch>
      </ConnectedRouter>

      <AppBar position="static" className={classes.footerBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Tous droits réservés 2020 © Lire la Musique{/*} – <Link to="/informations-legales">informations légales</Link>*/}
          </Typography>
        </Toolbar>
      </AppBar>
    </Router>
  );
}

export default App;
