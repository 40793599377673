import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as actions from "../../actions";

import Game from '../../components/Game'

const mapStateToProps = state => state;

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch),
});

const GameContainer = connect(
  mapStateToProps,
	mapDispatchToProps,
)(Game)

export default GameContainer;