import * as types from  "../constants/ActionsTypes"
import { GAME_MINUTES } from "../constants/GameConstants"

export const setCurrentPlayer = (player) => ({
	type: types.SET_CURRENT_PLAYER,
	player,
});

export const startGame = () => ({
  type: types.START_GAME,
  timeInMinutes: GAME_MINUTES,
});

export const clearCurrentCardsGame = () => ({
	type: types.CLEAR_CURRENT_CARDS_GAME
});

export const chooseValueGame = () => ({
	type: types.CHOOSE_VALUE_GAME
});

export const haveChooseGame = () => ({
  type: types.HAVE_CHOOSE_GAME,
});

export const duelFinishedGame = () => ({
  type: types.DUEL_FINISHED_GAME
});

export const endGame = () => ({
  type: types.END_GAME
});

export const resetResult = () => ({
  type: types.RESET_RESULT
});

export const setCards = (cards, idCardUpdated) => ({
  type: types.SET_CARDS,
  cards,
  idCardUpdated
});

export const addWrongAnswer = () => ({
  type: types.ADD_WRONG_ANSWER
});