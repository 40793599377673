import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GameContainer from "../../containers/GameContainer";
import InscriptionForm from "../../components/InscriptionForm";
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import * as actions from "../../actions";

import { TABLE_POINTS } from "../../constants/CardsConstants";

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 379,
  },
  responsiveImage: {
    marginTop: "2em",
    width: "40%",
    height: "auto"
  },
  titleH2: {
    marginTop: "2em"
  },
  titleH4: {
    marginTop: "2em"
  },
  body1: {
    marginTop: "1em"
  },
  paper: {
    marginTop: "2em",
    padding: "1em"
  }
});

function Home (props) {
  const { setCurrentPlayer } = props.actions;
  const { currentPlayer, appRef } = props;
  const classes = useStyles();

  return(
    <Grid container>
      <Grid item xs={12}>
        { currentPlayer ? <GameContainer appRef={appRef} /> : <InscriptionForm setCurrentPlayer={setCurrentPlayer} />}
      </Grid>
      <Grid container item xs={12} justify="center">
        <Grid container item xs={12} justify="center">
          <img src={TABLE_POINTS}  alt="Nature" className={classes.responsiveImage}/>
        </Grid>

        <Grid container item xs={7} justify="center">
          <Paper elevation={10} className={classes.paper}>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Typography variant="h4" className={classes.titleH4}>
                  But du jeu
                </Typography>
              </Grid>
              
              <Grid container item xs={12}>
                <Typography variant="body1" className={classes.body1}>
                  Le gagnant est celui qui a le score le plus élevé dans le temps imparti.
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <Typography variant="h4" className={classes.titleH4}>
                  Déroulement du jeu
                </Typography>
              </Grid>
              
              <Grid container item xs={12}>
                <Typography variant="body1" className={classes.body1}>
                  Les cartes sont distribuées à l'ensemble des joueurs.<br/>
                  <br/>
                  Les joueurs posent simultanément une carte : celle qui a la valeur la plus forte, c'est-à-dire la durée la plus longue remporte le pli (toutes les cartes posées sur la table) et augmente son score.
                  On répète ce procédé jusqu'à la fin de la partie. Exemple : la ronde qui vaut 4 l'emporte sur la blanche qui ne vaut que 2.
                  Avant chaque duel, le joueur doit choisir la bonne durée de la carte qu'il possède
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <Typography variant="h4" className={classes.titleH4}>
                  Bataille
                </Typography>
              </Grid>
              
              <Grid container item xs={12}>
                <Typography variant="body1" className={classes.body1}>
                  Quand il y a deux cartes de la même valeur, il y a "bataille". Chaque joueur place alors une 2ème carte face cachée sur sa 1ère carte posée, puis une 3ème carte face découverte.
                  La carte du joueur ayant la carte la plus forte remporte le pli et augmente son score, sauf si il y a une nouvelle bataille. Dans ce cas, on répète le procédé jusqu'à avoir deux cartes de valeurs différentes.
                </Typography>
              </Grid>

              <Grid container item xs={12}>
                <Typography variant="h5" className={classes.titleH4}>
                  Explications vidéo sur <a href="https://www.lirelamusique.fr">www.lirelamusique.fr</a>
                </Typography>
              </Grid>
              
              <Grid container item xs={12}>
                <Typography variant="body2" className={classes.body1}>
                  Conception de la bataille rythmique par Pierre Ledru.  
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
};

const mapStateToProps = state => ({
  currentPlayer: state.game.currentPlayer
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
	mapDispatchToProps,
)(Home);