import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import GameService from "../../services/GameService";
import * as actions from "../../actions";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: "7em",
  },
  statsTable: {
    marginTop: "5em"
  },
  titleScore: {
    marginTop: "5em"
  },
  circular: {
    marginTop: "2em"
  }
});

function createData(name, today, total) {
  return { name, today, total };
}

function Stats (props) {
  const { currentPlayer } = props;
  const [rows, setRows] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      const gameCountRes = await GameService.getGameCount(currentPlayer);
      const gameCountTodayRes = await GameService.getGameCountToday(currentPlayer);
      const correctAnswersCount = await GameService.getCorrectAnswersCount(currentPlayer);
      const correctAnswersCountToday = await GameService.getCorrectAnswersCountToday(currentPlayer);
      const percentageCorrectAnswers = await GameService.getPercentageCorrectAnswers(currentPlayer);
      const percentageCorrectAnswersToday = await GameService.getPercentageCorrectAnswersToday(currentPlayer);

      setRows([
        createData('Nombre de parties jouées', gameCountTodayRes.data, gameCountRes.data),
        //createData('Temps moyen pour retourner une carte', 237, 9.0),
        createData('Nombre de bonnes réponses', (correctAnswersCountToday.data) ? correctAnswersCountToday.data : 0, (correctAnswersCount.data) ? correctAnswersCount.data : 0),
        createData('Taux de réussite', percentageCorrectAnswersToday.data, percentageCorrectAnswers.data)
      ]);
    }

    fetchData();
    // return () => {}; => componentWillUnmount
  }, []);

  return(
    <Grid container justify="center" className={classes.container}>
      <Grid container item xs={12} justify="center" className={classes.titleGrid}>
        <Typography variant="h2">
          Statistiques
        </Typography>
      </Grid>
      <Grid container item xs={6} justify="center">
        {rows.length > 0 ? (
          <TableContainer component={Paper} className={classes.statsTable}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">Aujourd'hui</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.today}</TableCell>
                    <TableCell align="right">{row.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : <CircularProgress color="secondary" className={classes.circular}/>}
      </Grid>
    </Grid>
  )
};

const mapStateToProps = state => ({
  currentPlayer: state.game.currentPlayer
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
	mapDispatchToProps,
)(Stats);
