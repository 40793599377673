import { 
  SET_CARDS
} from "../constants/ActionsTypes"
import { DEFAULT_STATE_CARDS } from "../constants/CardsConstants"

const cardsFilter = (state = DEFAULT_STATE_CARDS, action) => {
  const newState = { ...state };

	switch (action.type) {
		default:
			return state
	}
}

export default cardsFilter