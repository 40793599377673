import { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import RectoCard from "../RectoCard";
import VersoCard from "../VersoCard";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import NoteService from "../../services/NoteService";

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginTop: "3em"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function LineGame({ player, atLeft, isBattle, canPlay, actionType, setCanPlay }) {
  const classes = useStyles();
  const [currentImageData, setCurrentImageData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if(player.currentCard) {
        let routeImage = null;
        const res = await NoteService.getNoteById(player.currentCard.note.id);
        if(res.data && res.data.card_image) {
          routeImage = res.data.card_image.url;
        }

        /*const data = {
          uuid: uuid(),
          routeImage
        };*/

        setCurrentImageData(routeImage);
      }
    }

    fetchData();
    // return () => {}; => componentWillUnmount
  }, [player.currentCard]);

  return (
    <Grid container justify="center" style={{ padding: "1em" }}>
      {atLeft ? (
        <>
          <Grid item xs={6}>
            <VersoCard style={{ marginLeft: "auto" }} />
          </Grid>
          <Grid item xs={6}>
            <RectoCard visibleFaceImage={currentImageData} canPlay={canPlay} player={player} isBattle={isBattle} actionType={actionType} setCanPlay={setCanPlay}/>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <RectoCard style={{ marginLeft: "auto" }} visibleFaceImage={currentImageData} canPlay={canPlay} player={player} isBattle={isBattle} actionType={actionType} setCanPlay={setCanPlay}/>
          </Grid>
          <Grid item xs={6}>
            <VersoCard />
          </Grid>
        </>
      )}
      
      <Grid container item xs={12} justify="center">
        <Grid item xs={4}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography variant="h5" component="h2">
                Score
              </Typography>
              <Typography variant="h5" component="h2">
                {player.currentScore}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
};