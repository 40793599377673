import getInstance from "./getInstance";

import qs from "qs";

export default {
  async saveGame(game) {
    try {
      const res = await getInstance().post("/games", game);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getGameCount(player) {
    try {
      const res = await getInstance().get(`/games/count?player=${player.id}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getGameCountToday(player) {
    try {
      const query = qs.stringify({
        _where: {
          date: new Date(),
          player: player.id
        }
      });
      const res = await getInstance().get(`/games/count?${query}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getCorrectAnswersCount(player) {
    try {
      const res = await getInstance().get(`/games/sum/correct-answers/${player.id}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getCorrectAnswersCountToday(player) {
    try {
      const res = await getInstance().get(`/games/sumtoday/correct-answers/${player.id}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getPercentageCorrectAnswers(player) {
    try {
      const res = await getInstance().get(`/games/percent/correct-answers/${player.id}`);
      return res;
    } catch (err) {
      return err;
    }
  },

  async getPercentageCorrectAnswersToday(player) {
    try {
      const res = await getInstance().get(`/games/percenttoday/correct-answers/${player.id}`);
      return res;
    } catch (err) {
      return err;
    }
  },
};