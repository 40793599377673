import { useState } from "react";
import { Formik, Form } from "formik";
import { FormikTextField } from 'formik-material-fields';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PlayerService from "../../services/PlayerService";
import CircularProgress from '@material-ui/core/CircularProgress';

import { INSCRIPTION_FORM_SCHEMA, INITIAL_VALUES_INSCRIPTION } from "../../constants/Schemas";
import Player from "../../models/Player";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "7em"
  }
}));

export default function InscriptionForm({ setCurrentPlayer }) {
  const [loading, setLoading] = useState(false); 

  const classes = useStyles();

  return (
    <Grid container align="center" direction="column" className={classes.container}>
      <Formik
        initialValues={INITIAL_VALUES_INSCRIPTION}
        validationSchema={INSCRIPTION_FORM_SCHEMA}
        onSubmit={async player => {
          setLoading(true);
          // verify if exist in database and then if exist find data, else if not exist create new player
          let res = await PlayerService.getPlayerByEmail(player.email);
          //console.log(res)
          if(res.status === 200){
            setCurrentPlayer(Player.createPlayerFromJSON(res.data));
          } else {
            res = await PlayerService.createPlayer(player);

            if(res.status === 200){
              setCurrentPlayer(Player.createPlayerFromJSON(res.data));
            } else {
              console.error("The server has a problem");
            }
          }

          setLoading(false);
        }}
      >
        {({ handleSubmit }) => {
          // console.log(errors);
          return (
            <Form onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}>
              <Grid item xs={6}>
                <Card>
                  <CardContent>
                    {loading ? <CircularProgress color="secondary" /> : null}

                    <Typography variant="h5">
                      Complétez ce formulaire afin de jouer !
                    </Typography>

                    <FormikTextField
                      autoFocus
                      margin="dense"
                      label="Prénom"
                      type="text"
                      name="name"
                      fullWidth
                    />

                    <FormikTextField
                      autoFocus
                      margin="dense"
                      label="Email"
                      type="text"
                      name="email"
                      fullWidth
                    />
                  </CardContent>
                  
                  <CardActions>
                    <Button variant="contained" color="primary" type="submit">
                      Confirmer
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  )
}