import { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import { MIN_CARDS_IN_GAME } from "../../constants/GameConstants";
import NoteService from "../../services/NoteService";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ParameterCard({ note, style, cardsOfCurrentPlayer, setCards }) {
  const [state, setState] = useState({
    checked: false,
    disabled: true,
    isValid: false
  });
  const [loading, setLoading] = useState(false);

  //console.log(note.card_image.url)

  const useStyles = makeStyles({
    container: {
      position: "relative",
      cursor: "pointer",
      width: "150px",
      height: "250px",
      display: "flex",
      borderRadius: "0.25rem",
      userSelect: "none",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${note.card_image.url})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'white',
      margin: "1em",
      opacity: state.disabled ? 0.35 : null,
      ...style
    },
    circular: {
      marginTop: "1em"
    }
  });
  
  const classes = useStyles();

  useEffect(() => {
    const newState = { ...state };
    const cardFilter = cardsOfCurrentPlayer.filter(card => card.note.id === note.id);
    const card = cardFilter[0];

    newState.disabled = !card.is_unlocked;
    newState.checked = card.in_game;
    newState.isValid = card.is_valid;

    setState(newState);
  }, []);

  const handleChange = async () => {
    try {
      const newState = { ...state };
      const cardFilter = cardsOfCurrentPlayer.filter(card => card.note.id === note.id);
      const cardsInGame = cardsOfCurrentPlayer.filter(card => card.in_game === true && card.is_unlocked === true);
      const otherCards = cardsOfCurrentPlayer.filter(card => card.note.id !== note.id);
      const boolean = !newState.checked;
      const nextLength = cardsInGame.length + (boolean ? 1 : -1);
      const card = cardFilter[0];

      if(nextLength >= MIN_CARDS_IN_GAME) {
        setLoading(true);
        newState.checked = boolean;
        card.in_game = boolean;
        await NoteService.updateCardPlayer(card);
        setCards([...otherCards, card]);
        setState(newState);
        setLoading(false);
      }
    } catch(e) {
      console.error(e);
    }
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: state.isValid ? "green" : null
    }}
    onClick={state.disabled ? null : handleChange}>
      {loading ? <CircularProgress color="secondary" className={classes.circular} /> : null}

      <div 
        className={classes.container}>
      </div>

      <Switch
        checked={state.checked}
        disabled={state.disabled}
      />
    </div>
  )
}