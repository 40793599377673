export const SET_CURRENT_PLAYER = "SET_CURRENT_PLAYER";
export const START_GAME = "START_GAME"; // on commence la partie, le minuteur démarre
export const CLEAR_CURRENT_CARDS_GAME = "CLEAR_CURRENT_CARDS_GAME"; // chaque joueur change de carte
export const CHOOSE_VALUE_GAME = "CHOOSE_VALUE_GAME"; // chaque joueur doit choisir la valeur de sa carte
export const HAVE_CHOOSE_GAME = "HAVE_CHOOSE_GAME"; // évènement déclenché lorsque chaque joueur a choisi. on compare les cartes et on révèle si il y a égalité. On recommence le process si il y a égalité et que les joueurs doivent choisir.
export const DUEL_FINISHED_GAME = "DUEL_FINISHED_GAME"; // un joueur a remporté la manche et remporte les plis
export const END_GAME = "END_GAME"; // fin de la partie
export const RESET_RESULT = "RESET_RESULT";
export const ADD_WRONG_ANSWER = "ADD_WRONG_ANSWER";

export const SET_CARDS = "SET_CARDS";