import { 
  SET_CURRENT_PLAYER,
  START_GAME,
  CLEAR_CURRENT_CARDS_GAME,
  CHOOSE_VALUE_GAME,
  HAVE_CHOOSE_GAME,
  ADD_WRONG_ANSWER,
  END_GAME,
  RESET_RESULT,
  SET_CARDS
} from "../constants/ActionsTypes";
import { LOCATION_CHANGE } from 'connected-react-router';
import { DEFAULT_STATE_GAME, GAME_MINUTES } from "../constants/GameConstants"
import { getRandomNumberBetween } from "../utils";
import Player from "../models/Player";
import GameService from "../services/GameService";
import NoteService from "../services/NoteService";
import PlayerService from "../services/PlayerService";

let cardsInGame = [];
let currentCard = null;
let currentCardForComputer = null;
let cardsUnlocked = [];

const gameFilter = (state = DEFAULT_STATE_GAME, action) => {
  let newState = { ...state };
  newState.actionType = action.type;

  const fetchData = async newState => {
    const resultCurrentPlayer = newState.result.filter(resultPlayer => resultPlayer.id === newState.currentPlayer.id)[0];
    
    const res = await GameService.saveGame({
      date: new Date(),
      is_winner: resultCurrentPlayer.winner,
      score: resultCurrentPlayer.currentScore,
      nb_correct_answers: newState.nb_correct_answers,
      nb_wrong_answers: newState.nb_wrong_answers,
      player: newState.currentPlayer.id
    });

    return res;
  };

  const fetchDataCardUpdated = async cardsUpdated => {
    const res = await NoteService.updateCardsPlayer(cardsUpdated, newState.currentPlayer);

    for(const cardUpdated of res.data.cardsUnlocked) {
      newState.currentPlayer.notes = newState.currentPlayer.notes.map(card => card.id === cardUpdated.id ? cardUpdated : card);
    }

    cardsUnlocked = res.data.cardsUnlocked;
  };

  const updateDataCurrentPlayer = async () => {
    const res = await PlayerService.getPlayerByEmail(newState.currentPlayer.email);

    if(res.status === 200){
      newState.currentPlayer = Player.createPlayerFromJSON(res.data);
      //console.log(newState.currentPlayer);
    } else {
      console.error("The server has a problem");
    }
  }

	switch (action.type) {
    case SET_CURRENT_PLAYER:
      newState.currentPlayer = action.player;
      newState.currentComputer = new Player();

      break;
    case START_GAME:
      cardsInGame = newState.currentPlayer.notes.filter(card => (card.is_unlocked === true && card.in_game === true));
      currentCard = cardsInGame[getRandomNumberBetween(0, cardsInGame.length - 1)];
      currentCardForComputer = cardsInGame[getRandomNumberBetween(0, cardsInGame.length -1)];

      newState.currentPlayer.setCurrentCard(currentCard);
      newState.currentComputer.setCurrentCard(currentCardForComputer);
      newState.timeInMinutes = GAME_MINUTES;
      newState.gameProcessing = true;

      newState.cardsPlayed.push(currentCard);
      newState.cardsPlayed.push(currentCardForComputer);
      newState.cardsUpdated = [];
      newState.cardsPlayed = [];
      newState.cardsUnlocked = [];
      newState.cardsValid = [];

      break;
    case ADD_WRONG_ANSWER:
      newState.nb_wrong_answers++;
      break;
    case HAVE_CHOOSE_GAME:
      newState.nb_correct_answers++;
      cardsInGame = newState.currentPlayer.notes.filter(card => (card.is_unlocked === true && card.in_game === true));
      currentCard = cardsInGame[getRandomNumberBetween(0, cardsInGame.length - 1)];
      currentCardForComputer = cardsInGame[getRandomNumberBetween(0, cardsInGame.length -1)];

      if(newState.currentPlayer.currentCard.note.value > newState.currentComputer.currentCard.note.value) {
        newState.isBattle = false;
        newState.currentPlayer.addToScore(newState.cardsPlayed.length);
        newState.cardsPlayed = [];
        newState.cardsPlayed.push(currentCard);
        newState.cardsPlayed.push(currentCardForComputer);
      } else if (newState.currentPlayer.currentCard.note.value < newState.currentComputer.currentCard.note.value) {
        newState.isBattle = false;
        newState.currentComputer.addToScore(newState.cardsPlayed.length);
        newState.cardsPlayed = [];
        newState.cardsPlayed.push(currentCard);
        newState.cardsPlayed.push(currentCardForComputer);
      } else if (newState.currentPlayer.currentCard.note.value === newState.currentComputer.currentCard.note.value){
        newState.isBattle = true;
        newState.cardsPlayed.push({});
        newState.cardsPlayed.push({});
      }

      newState.currentPlayer.setCurrentCard(currentCard);
      newState.currentComputer.setCurrentCard(currentCardForComputer);

      break;
    case END_GAME:
      const cardsValid = newState.currentPlayer.notes.filter(card => newState.cardsUpdated.includes(card.id) && !card.is_valid);

      newState.gameProcessing = false;
      newState.cardsPlayed = [];

      newState.result = [
        { id: newState.currentPlayer.id, name: newState.currentPlayer.name, currentScore: newState.currentPlayer.currentScore },
        { id: newState.currentComputer.id , name: newState.currentComputer.name, currentScore: newState.currentComputer.currentScore },
      ];

      newState.result.sort((a, b) => b.currentScore > a.currentScore ? 1 : -1);
      newState.result[0].winner = true;

      const resultWinner = newState.result[0];
      for(let i = 1; i < newState.result.length; i++) {
        const resultPlayer = newState.result[i];

        if(resultPlayer.currentScore === resultWinner.currentScore) {
          newState.result[i].winner = true;
        }
      }

      fetchData(newState);
      fetchDataCardUpdated(cardsValid);

      newState.currentComputer.resetCurrentScore();
      newState.currentPlayer.resetCurrentScore();
      newState.currentComputer.resetCurrentCard();
      newState.currentPlayer.resetCurrentCard();

      newState.currentPlayer.notes = newState.currentPlayer.notes.map(card => {
        card.good_following_responses = 0;
        return card;
      });

      newState.nb_correct_answers = 0;
      newState.nb_wrong_answers = 0;

      newState.cardsValid = cardsValid;
      break;
    case RESET_RESULT: 
      newState.result = null;
      newState.cardsUnlocked = [];
      newState.cardsUpdated = [];
      newState.cardsValid = [];

      break;
    case SET_CARDS:
      if(action.cards) {
        newState.currentPlayer.notes = action.cards;

        if(action.idCardUpdated) {
          newState.cardsUpdated.push(action.idCardUpdated);
        }
      }

      //console.log(newState.cardsUpdated)

      break;
    case LOCATION_CHANGE:
      if(newState.currentComputer && newState.currentPlayer) {
        newState.currentComputer.resetCurrentScore();
        newState.currentPlayer.resetCurrentScore();
        newState.currentComputer.resetCurrentCard();
        newState.currentPlayer.resetCurrentCard();
      }

      newState.result = null;
      newState.gameProcessing = false;
      newState.cardsPlayed = [];
      newState.nb_correct_answers = 0;
      newState.nb_wrong_answers = 0;
      newState.cardsUpdated = [];
      newState.cardsUnlocked = [];
      newState.cardsValid = [];

      break;
  }
  
  return newState;
}

export default gameFilter