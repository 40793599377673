import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import StarIcon from '@material-ui/icons/Star';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
});

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  titleMessage: {
    marginTop: "2em"
  }
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ResultModal({ open, result, onClose, cardsUnlocked, cardsValid }) {
  const classes = useStyles();

  const cards = cardsValid.filter(card => card.good_following_responses >= 5);

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        Résultats
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Gagnant</TableCell>
                <TableCell align="left">Joueur</TableCell>
                <TableCell align="left">Points</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result ? (
                (
                  <>
                    {result.map(resultOfPlayer => (
                      <TableRow key={resultOfPlayer.id}>
                        <TableCell align="left" scope="row">
                          {resultOfPlayer.winner ? (
                            <ListItemIcon>
                              <StarIcon />
                            </ListItemIcon>
                          ) : null}
                        </TableCell>
                        <TableCell align="left">{resultOfPlayer.name}</TableCell>
                        <TableCell align="left">{resultOfPlayer.currentScore}</TableCell>
                      </TableRow>
                    ))}
                  </>
                )
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        
        {cards.length > 0 ? (
          <>
            <Typography variant="body1" className={classes.titleMessage}>
              Tu as validé les cartes suivantes :
              {cards.map(card => (
                <ListItem key={card.id}>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>

                  <ListItemText primary={card.note.name} />
                </ListItem>
              ))}
            </Typography>
            <Typography variant="body1">
              Tu as sûrement débloqué d'autres cartes ! Tu peux choisir de jouer avec dans tes paramètres.
            </Typography>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}