import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 379,
  },
  responsiveImage: {
    marginTop: "2em",
    width: "40%",
    height: "auto"
  },
  titleH2: {
    marginTop: "2em"
  }
});

export default function Home () {
  const classes = useStyles();

  return(
    <Grid container></Grid>
  )
};