import { makeStyles } from '@material-ui/core/styles';

import { VERSO_CARD_URI } from "../../constants/CardsConstants";

export default function VersoCard({ style }) {
  const useStyles = makeStyles({
    container: {
      position: "relative",
      width: "150px",
      height: "250px",
      display: "flex",
      borderRadius: "0.25rem",
      userSelect: "none",
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${VERSO_CARD_URI})`,
      backgroundRepeat: 'no-repeat',
      margin: "1em",
      ...style
    },
  });

  const classes = useStyles();

  return (
    <div 
      className={classes.container}>
    </div>
  )
}