import { useState } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import screenfull from "screenfull";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "3em"
  },
  params: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

export default function MenuGame({ onStartGame, onEndGame, gameProcessing }) {
  const [textFullscreen, setTextFullscreen] = useState("Plein écran")
  const classes = useStyles();
  const history = useHistory();

  const links = [];

  if(gameProcessing) {
    links.push({ id: 1, text: textFullscreen, onClick: () => {
      if (screenfull.isEnabled) {
        if(screenfull.isFullscreen) {
          setTextFullscreen("Plein écran");
          screenfull.exit();
        } else {
          setTextFullscreen("Quitter plein écran");
          screenfull.request();
        }
      }
    } });
    links.push({ id: 4, text: "Quitter la partie", onClick: () => onEndGame() });
  } else {
    links.push({ id: 2, text: "Nouvelle partie", onClick: () => onStartGame() });
    //links.push({ id: 3, text: "Statistiques", onClick: () => history.push("/stats") });
  }

  const handleClickParams = () => history.push("/parametres");
  const handleClickStats = () => history.push("/statistiques");

  return (
    <Grid item container xs={12} justify="center" className={classes.container}>
        {links.map(link => (
          <Grid key={link.id} item container justify="center" xs={2}>
            <Button variant="contained" color="primary" onClick={link.onClick}>
              {link.text}
            </Button>
          </Grid>
        ))}
      
      <Grid item container xs={12} justify="center" className={classes.container}>
        <Grid item container justify="center" xs={1}>
          <Fab color="secondary" onClick={handleClickParams}>
            <SettingsIcon />
          </Fab>
        </Grid>
        <Grid item container justify="center" xs={1}>
          <Fab color="secondary" onClick={handleClickStats}>
            <FormatListNumberedIcon />
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  )
};
